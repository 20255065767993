import React from "react";

import ArchitectureIcon from "../images/service-icons/interior-design.svg";
import RenovationIcon from "../images/service-icons/building-renovation.svg";
import ConstructionIcon from "../images/service-icons/construction.svg";
import Eyebrow from "./eyebrow";
import ServiceItem from "./serviceItem";
import CenteredVideo from "./CenteredVideo";

const Services = () => {
  return (
    <div id="#services">
      <div className="container mx-auto">
        <div className="flex flex-col md:gap-20 gap-10 lg:py-28 md:py-20 py-12">
          <div className="grid lg:grid-cols-12 grid-cols-1 gap-8">
            <div className="lg:col-span-12 flex flex-col gap-12">
              <Eyebrow label="LEISTUNGEN" />
              <h2 className="font-display md:text-display-xl text-display-md pt-5">
                Die Kombination aus Tradition und Vision
              </h2>
			  <p className="md:text-body-lg text-body-md font-light text-neutral-700 font-bold">
              Unsere Leistungen resultieren nicht aus Glück.
            </p>
			  <p className="md:text-body-lg text-body-md font-light text-neutral-700">
			  Die Fähigkeit, mit der Zeit Schritt zu halten, ein hohes Maß an Professionalität und Menschlichkeit sowie die Freude an unserer Arbeit sind die Grundlagen, die uns zu einem kompetenten Partner für Ihr individuelles Wohnprojekt machen.
			  </p>
            </div>
          </div>
          <div className="flex lg:flex-row flex-col gap-8">
            <ServiceItem
              icon={ArchitectureIcon}
              title="Einschätzen der Bedürfnisse"
              description="Als Grundlage für die Konzeptentwicklung klären wir gemeinsam Ihre individuelle Lebens- und Wohnsituation und nehmen alle Ihre Wünsche auf"
            />
            <ServiceItem
              icon={RenovationIcon}
              title="Individuelle Planung"
              description="Planung ist Vertrauensache, dadurch ist es uns umso wichtiger den persönlichen Kontakt mit Ihnen aufzubauen und ein auf Ihre Bedürfnisse abgestimmtes Konzept zu entwickeln"
            />
            <ServiceItem
              icon={ConstructionIcon}
              title="Kreative Lösungen"
              description="Unter Kreativität verstehen wir die Entwicklung einer Lösung ohne uns von vorgefertigten Denkmustern einschränken zu lassen"
            />
          </div>
		  <div className="flex lg:flex-row flex-col gap-8">
            <ServiceItem
              icon={ArchitectureIcon}
              title="Nachhaltige Produktion"
              description="Bei der Auswahl der Materalien legen wir besonderes Augenmerk auf Regionalität und Nachhaltigkeit.
			   Für jedes abgeschlossene Projekt pflanzen wir einen Baum mit Ihrem Namen auf unserem Bergbauernhof"
            />
            <ServiceItem
              icon={RenovationIcon}
              title="Fachgerechte Montage"
              description="Ein Projekt ist für uns erst dann abgeschlossen, wenn wir mit einer fachgerechten Montage aus Meisterhand alle Ihre Wünsche zu Ihrer Zufriedenheit erledigen konnten"
            />
            <ServiceItem
              icon={ConstructionIcon}
              title="Gebaut fürs Leben"
              description="Bei unseren Konzepten achten wir besonders auf den Einklang von kreativem Design und langjähriger Alltagstauglichkeit"
            />
          </div>
		  <CenteredVideo />
        </div>
      </div>
    </div>
  );
};
export default Services;
