import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

import CtaButton from "../images/logo-button.png";
import HeroVideo from "../videos/kwd-video3.mp4";

const Hero = () => {
  const data = useStaticQuery(graphql`
    {
      heroimage: file(relativePath: { eq: "hero-image.png" }) {
        childImageSharp {
          gatsbyImageData(
            width: 5120
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
    }
  `);
  
  const videoContainerStyle = {
    paddingTop: "105px", // Adjust this value based on your header's height
  };

  return (
    <div style={videoContainerStyle}>
      <div className="relative">
        <video autoPlay loop muted playsInline className="w-full object-cover h-full">
          <source src={HeroVideo} type="video/mp4" />
        </video>
      </div>
    </div>
  );
};

export default Hero;
