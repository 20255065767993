import React from "react";
import Video from "../videos/kwd-video-full.mp4";
import VideoPoster from "../images/video-preview.png"; // Import the poster image

const CenteredVideo = () => {
  return (
    <div className="flex justify-center items-center mt-16">
      <video controls poster={VideoPoster}>
        <source src={Video} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </div>
  );
};

export default CenteredVideo;
