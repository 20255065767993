import React from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

import Eyebrow from "./eyebrow";
import ImageGallery from "./ImageGallery"; // Assuming this component is correctly defined and functional

import Button from "./button";

import h1 from "../images/works/familie-h/h1.jpg";
import h2 from "../images/works/familie-h/h2.jpg";
import h3 from "../images/works/familie-h/h3.jpg";
import h4 from "../images/works/familie-h/h4.jpg";
import h5 from "../images/works/familie-h/h5.jpg";
import h6 from "../images/works/familie-h/h6.jpg";
import h7 from "../images/works/familie-h/h7.jpg";
import h8 from "../images/works/familie-h/h8.jpg";
import h9 from "../images/works/familie-h/h9.jpg";
import h10 from "../images/works/familie-h/h10.jpg";
import h11 from "../images/works/familie-h/h11.jpg";

import g1 from "../images/works/familie-g/g1.jpg";
import g2 from "../images/works/familie-g/g2.jpg";
import g3 from "../images/works/familie-g/g3.jpg";
import g4 from "../images/works/familie-g/g4.jpg";
import g5 from "../images/works/familie-g/g5.jpg";

import k1 from "../images/works/familie-k/k1.jpg";
import k2 from "../images/works/familie-k/k2.jpg";
import k3 from "../images/works/familie-k/k3.jpg";
import k4 from "../images/works/familie-k/k4.jpg";
import k5 from "../images/works/familie-k/k5.jpg";
import k6 from "../images/works/familie-k/k6.jpg";
import k7 from "../images/works/familie-k/k7.jpg";
import k8 from "../images/works/familie-k/k8.jpg";
import k9 from "../images/works/familie-k/k9.jpg";
import k10 from "../images/works/familie-k/k10.jpg";
import k11 from "../images/works/familie-k/k11.jpg";
import k12 from "../images/works/familie-k/k12.jpg";
import k13 from "../images/works/familie-k/k13.jpg";

import b1 from "../images/works/familie-b/b1.jpg";
import b2 from "../images/works/familie-b/b2.jpg";
import b3 from "../images/works/familie-b/b3.jpg";
import b4 from "../images/works/familie-b/b4.jpg";
import b5 from "../images/works/familie-b/b5.jpg";
import b6 from "../images/works/familie-b/b6.jpg";
import b7 from "../images/works/familie-b/b7.jpg";
import b8 from "../images/works/familie-b/b8.jpg";
import b9 from "../images/works/familie-b/b9.jpg";
import b10 from "../images/works/familie-b/b10.jpg";
import b11 from "../images/works/familie-b/b11.jpg";
import b12 from "../images/works/familie-b/b12.jpg";
import b13 from "../images/works/familie-b/b13.jpg";
import b14 from "../images/works/familie-b/b14.jpg";
import b15 from "../images/works/familie-b/b15.jpg";

import { Link } from "gatsby";




const Works = () => {
  // Create image sets for each gallery
  const imageSets = [

    {
      title: "Küche Familie H.", // Add title for Gallery 2
      images: [
        { childImageSharp: { gatsbyImageData: { src: h1 } } },
        { childImageSharp: { gatsbyImageData: { src: h2 } } },
        { childImageSharp: { gatsbyImageData: { src: h3 } } },
        { childImageSharp: { gatsbyImageData: { src: h4 } } },
        { childImageSharp: { gatsbyImageData: { src: h5 } } },
        { childImageSharp: { gatsbyImageData: { src: h6 } } },
        { childImageSharp: { gatsbyImageData: { src: h7 } } },
        { childImageSharp: { gatsbyImageData: { src: h8 } } },
        { childImageSharp: { gatsbyImageData: { src: h9 } } },
        { childImageSharp: { gatsbyImageData: { src: h10 } } },
        { childImageSharp: { gatsbyImageData: { src: h11 } } },
      ],
      description: "Front mit durchlaufender Maserrichtung und versteckten Durchgangstüre. ​Front Weiß matt mit Dekor in Berglärche und ​Natursteinarbeitsplatte kombiniert.​", // Add your individual description
    },
    {
      title: "Küche Familie G.", // Add title for Gallery 3
      images: [
        { childImageSharp: { gatsbyImageData: { src: g1 } } },
        { childImageSharp: { gatsbyImageData: { src: g2 } } },
        { childImageSharp: { gatsbyImageData: { src: g3 } } },
        { childImageSharp: { gatsbyImageData: { src: g4 } } },
        { childImageSharp: { gatsbyImageData: { src: g5 } } },
      ],
      description: "Eine Küche mit Eichen-Echtholz-Elementen und integriertem Kachelofen mit Kochmöglichkeit.", // Add your individual description
    },
    {
      title: "Küche Familie K.", // Add title for Gallery 3
      images: [
        { childImageSharp: { gatsbyImageData: { src: k1 } } },
        { childImageSharp: { gatsbyImageData: { src: k2 } } },
        { childImageSharp: { gatsbyImageData: { src: k3 } } },
        { childImageSharp: { gatsbyImageData: { src: k4 } } },
        { childImageSharp: { gatsbyImageData: { src: k5 } } },
        { childImageSharp: { gatsbyImageData: { src: k6 } } },
        { childImageSharp: { gatsbyImageData: { src: k7 } } },
        { childImageSharp: { gatsbyImageData: { src: k8 } } },
        { childImageSharp: { gatsbyImageData: { src: k9 } } },
        { childImageSharp: { gatsbyImageData: { src: k10 } } },
        { childImageSharp: { gatsbyImageData: { src: k11 } } },
        { childImageSharp: { gatsbyImageData: { src: k12 } } },
        { childImageSharp: { gatsbyImageData: { src: k13 } } },
      ],
      description: "Insel mit Sitzbank und rundumlaufenden Griffmulden. Front Weiß matt mit Dekor in Wildeiche und Natursteinarbeitsplatte kombiniert.​", // Add your individual description
    },
    {
      title: "Küche Familie B.", // Add title for Gallery 3
      images: [
        { childImageSharp: { gatsbyImageData: { src: b1 } } },
        { childImageSharp: { gatsbyImageData: { src: b2 } } },
        { childImageSharp: { gatsbyImageData: { src: b3 } } },
        { childImageSharp: { gatsbyImageData: { src: b4 } } },
        { childImageSharp: { gatsbyImageData: { src: b5 } } },
        { childImageSharp: { gatsbyImageData: { src: b6 } } },
        { childImageSharp: { gatsbyImageData: { src: b7 } } },
        { childImageSharp: { gatsbyImageData: { src: b8 } } },
        { childImageSharp: { gatsbyImageData: { src: b9 } } },
        { childImageSharp: { gatsbyImageData: { src: b10 } } },
        { childImageSharp: { gatsbyImageData: { src: b11 } } },
        { childImageSharp: { gatsbyImageData: { src: b12 } } },
        { childImageSharp: { gatsbyImageData: { src: b13 } } },
        { childImageSharp: { gatsbyImageData: { src: b14 } } },
        { childImageSharp: { gatsbyImageData: { src: b15 } } },
      ],
      description: "Front weiß matt, mit Designelementen in Antikeiche.​ Rückwand mit gespaltenen Eichenlamellen​. Arbeitsplatte Naturstein mit Tropfrillen und versteckten Mülleimer.​", // Add your individual description
    },
  ];

  return (
    <div id="#works">
      <div className="container mx-auto">
        <div className="flex flex-col gap-12 lg:py-28 md:py-24 py-12">
          <Eyebrow label="UNSERE ARBEIT" />
          <h3 className="font-display md:text-display-xl text-display-md font-normal pb-4">
            Einige<span className="italic">unserer Projekte</span> mit Liebe gemacht
          </h3>
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-8">
            {/* Map through each image set to create a gallery */}
            {imageSets.map((set, index) => (
              <div key={index}>
                <ImageGallery images={set.images} />
                <div className="flex flex-col self-stcdretch pt-6">
                  <h3 className="font-display text-display-md pb-4">{set.title}</h3>
                  <p className="text-body-lg font-light text-neutral-700">{set.description}</p>
                </div>
              </div>
            ))}
          </div>
          <div className="grid grid-cols-6">
          <Link
              spy={true}
              smooth={true}
              duration={500}
              to="/references"
              className="inline-block cursor-pointer col-start-1 col-end-4 pt-8"
            >
              <Button label="MEHR PROJEKTE >>>" />
            </Link>
            </div>
            </div>
        </div>
      </div>
  );

};

export default Works;
